$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome";

@import "~@edx/brand/paragon/fonts";
@import "~@edx/brand/paragon/variables";
@import "~@openedx/paragon/scss/core/core";
@import "~@edx/brand/paragon/overrides";

@import "~@edx/frontend-component-header/dist/index";
@import "~@edx/frontend-component-footer/dist/footer";

@import "./console/style";

footer a img {
  max-height: 50px;
}
